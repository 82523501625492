<template>
  <div
    v-if="pageLoading"
    class="text-center w-full py-24 h-full sm:py-28 md:py-36 lg:py-52"
  >
    <base-svg
      class="h-4 w-4 mr-1 text-primary-red inline-block"
      src="icons/circleSpinner.svg"
      tag="span"
    />
    Loading ...
  </div>
  <div
    v-else
    class="flex flex-col md:flex-row mb-20"
  >
    <style-guide-side-bar class="w-full md:max-w-sm flex-shrink-0" />
    <basket-section class="flex-grow px-4" />
  </div>
  <app-footer />
</template>

<script>
import { defineAsyncComponent, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import AppFooter from '@/components/AppFooter.vue';
export default {
    name: 'Basket',

    components: {
        StyleGuideSideBar: defineAsyncComponent(() => import('@/components/style-guide-sidebar/StyleGuideSidebar.vue')),
        BasketSection: defineAsyncComponent(() => import('@/components/style-guide-basket-section/BasketSection.vue')),
        AppFooter,
        BaseSvg
    },

    setup () {
        const store = useStore();

        const styleGuideDirectory = computed(() => store.getters['styleGuideTree/getStyleGuideDirectory']);
        const pageLoading = ref(true);
        const fetchDirectoryData = async () => {
            try {
                pageLoading.value = true;
                await store.dispatch('styleGuideTree/fetchStyleGuideDirectory', {
                    params: {
                        metadataTemplateName: 'Cover'
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                pageLoading.value = false;
            }
        };

        onMounted(() => {
            if (styleGuideDirectory.value) {
                pageLoading.value = false;
            } else {
                fetchDirectoryData();
            }
        });

        return {
            pageLoading
        };
    }
};
</script>
